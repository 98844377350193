import React from 'react'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Layout from '../components/Layout'
import Alert from '../components/Alert'
import SEO from '../components/Seo'
import '../assets/style.category.css'

const Category = ({ data, pageContext }) => {
  const { products } = data
  return (
    <Layout>
      <SEO description={pageContext.description} />

      <h1 className='invisible'>{pageContext.category}</h1>

      <div className='w-11/12 mx-auto md:mx-0 mt-2 md:w-4/5'>
        <Link className='flex robika w-full sm:w-4/5 lg:w-2/3 uppercase underlin text-xs sm:text-sm md:text-base lg:text-lg xl:text-xl md:mt-10 mb-2 font-bold' to='/produtos'>{pageContext.category}</Link>
      </div>

      {/* <nav id='store' className='w-full top-0 px-6 py-1'>
        <div className='w-full container mx-auto flex flex-wrap items-center justify-between mt-0 px-2 py-3'>
          <div className='flex items-center' id='store-nav-content'>
            TODO: Implementar ordenação (aA-zZ / zZ-aA)
            <a className='pl-3 inline-block no-underline hover:text-black' href='?filter'>
              <svg className='fill-current hover:text-black' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M7 11H17V13H7zM4 7H20V9H4zM10 15H14V17H10z' />
              </svg>
            </a>
            TODO: Implementar busca com InstantSearch e Algolia
            <a className='pl-3 inline-block no-underline hover:text-black' href='?search'>
              <svg className='fill-current hover:text-black' xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'>
                <path d='M10,18c1.846,0,3.543-0.635,4.897-1.688l4.396,4.396l1.414-1.414l-4.396-4.396C17.365,13.543,18,11.846,18,10 c0-4.411-3.589-8-8-8s-8,3.589-8,8S5.589,18,10,18z M10,4c3.309,0,6,2.691,6,6s-2.691,6-6,6s-6-2.691-6-6S6.691,4,10,4z' />
              </svg>
            </a>
          </div>
        </div>
      </nav> */}

      <section className='vitrine'>
        <div className='container mx-auto flex items-center flex-wrap pt-4 pb-12'>

          { // Alerta de categoria vazia
            products.edges.length === 0 && (
              <Alert title='Aguarde' >Em breve adicionaremos novos produtos.</Alert>
            )
          }

          {
            products.edges.map(product => {
              return (
                <div key={product.node.slug} className='w-full md:w-1/3 xl:w-1/4 p-2 flex flex-col'>
                  <div className='blur bg-white md:hover:shadow-2xl md:hover:grow px-2 pt-2 pb-4 md:hover:bg-gray-400'>
                    <Link className='absolute bg-transparent text-white shadow-lg border-2 border-solid font-bold px-2 hidden-btn' to={'/produtos/'+product.node.slug}>VER DETALHES</Link>
                    <Link to={'/produtos/'+product.node.slug}>
                      <Img className='shadow bg-white' fluid={product.node.images[0].fluid} alt={product.node.images[0].description}/>
                      <div className='pt-3 flex items-center'>
                        <p className='pl-4 font-bold'>{product.node.product}</p>
                      </div>
                    </Link>
                  </div>
                </div>
              )
            })
          }
        </div>
      </section>
    </Layout>
  )
}

export const pageQuery = graphql`
  query($slug: String!) {
    products: allContentfulProduct(filter: {categories: {elemMatch: {slug: {eq: $slug}}}}) {
      edges {
        node {
          product
          slug
          images {
            description
            fluid(maxWidth: 480, maxHeight: 320) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default Category
